@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-300.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-500.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-700.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-900.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Roboto, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #5B3EEF;


    --btn1-background-color: #25D2FE;
    --btn1-background-hover-color: #06AFDA;
    --btn1-text-color: #000000;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #25D2FE;
    --btn1-outline-text-color: #000000;
    --btn1-outline-text-color-hover: #000000;
    --btn1-outline-border-color: #25D2FE;
    --btn1-outline-border-hover-color: #25D2FE;


    --btn2-background-color: #5B3EEF;
    --btn2-background-hover-color: #4530b1;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #5B3EEF;
    --btn2-outline-text-color: #5B3EEF;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #5B3EEF;
    --btn2-outline-border-hover-color: #5B3EEF;


    --color-1: #25D2FE;
    --color-2: #5B3EEF;


    --course-border: transparent;
    --course-radius: 0;
    --course-background: #ffffff;
    --course-background-hover: #fafafa;
    --course-color: #1D1D1D;
    --course-color-hover: #1D1D1D;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}


.btn{
    border-radius: 0.25em;
    font-size: 18px;
    padding: 0.52em 1.2em;
    letter-spacing: 0;

    @media (max-width: 1199px){
        font-size: 16px;
    }
}

.navbar-nav .btn-1{
    background-color: var(--btn2-background-color);
    color: var(--btn2-text-color);
    border-color: var(--btn2-background-color);

    &:hover{
        background-color: var(--btn2-background-hover-color);
        color: var(--btn2-text-color);
        border-color: var(--btn2-background-hover-color);
    }
}
.navbar-nav .btn-1.btn-1-outline{
    background-color: var(--btn2-outline-background-color);
    color: var(--btn2-outline-text-color);
    border-color: var(--btn2-outline-border-color);

    &:hover{
        background-color: var(--btn2-outline-background-hover-color);
        color: var(--btn2-outline-text-color-hover);
        border-color: var(--btn2-outline-border-hover-color);
    }
}


.bg-landing{

    .landing-container-full{
        background: #0B1641;
        color: #ffffff;
    }
}


.theme-all-info-section-1{
    background-color: #0B1641;
    color: #ffffff;
    padding: 57px 20px 78px;
}
.theme-11-info-section-new{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }

    .theme-all-info-section-1-container, .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
