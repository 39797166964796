.home-h2{
    text-align: center;
    font-size: 36px;
    margin-bottom: 0.335em;
    font-weight: 700;

    @media (max-width: 1159px){
        font-size: 30px;
    }
    @media (max-width: 767px){
        font-size: 26px;
    }

    &:lang(hy){
        font-size: 32px;

        @media (max-width: 1159px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 24px;
        }
    }
}
.theme-11-header-left{
    color: #ffffff;
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 50px 24px 24px;

    @media (max-width: 767px){
        width: 100%;
        padding: 10vw 6.7vw 10vw 6.7vw;
    }
}
.theme-11-header-left-text{
    max-width: 480px;

    &:lang(hy){
        max-width: 580px;
    }
    h1{
        font-weight: 700;
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 12px;

        @media (max-width: 1199px){
            font-size: 4vw;
        }
        @media (max-width: 767px){
            font-size: 8vw;
        }

        &:lang(hy){
            font-size: 40px;

            @media (max-width: 1279px){
                font-size: 2.7vw;
            }
            @media (max-width: 767px){
                font-size: 5.3vw;
            }
        }
    }
    > p{
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 42px;

        @media (max-width: 991px){
            margin-bottom: 20px;
            font-size: 16px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.theme-11-header-left-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.theme-11-head-2-bg{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.theme-11-header-right{
    width: 49%;
    padding: 5.15vw 6.5vw 6.7vw 0;
    position: relative;

    @media (max-width: 1199px){
        padding: 5.15vw 5vw 4.2vw 0;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 6vw 0 6vw 0;
    }
}
.theme-11-header-right-img{
    margin-left: -28px;
    position: relative;

    @media (max-width: 767px){
        margin-left: 0;
    }
    > img{
        border-radius: 0 13.5vw 0 0;
        width: 100%;
        height: 26.55vw;
        object-fit: cover;

        @media (max-width: 767px){
            height: 53.55vw;
            border-radius: 0 26.5vw 0 0;
        }
    }
}
.theme-11-why{
    background-color: #0B1641;
    color: #ffffff;
    padding: 60px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 12px;
    }
}
.theme-11-why-item{
    width: 49%;
    max-width: 438px;
    padding: 12px 0;
    margin: 12px 0;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
    > img{
        width: 70px;

        @media (max-width: 1159px){
            width: 60px;
        }
        @media (max-width: 575px){
            width: 50px;
        }
    }
}
.theme-11-why-item-text{
    padding-left: 24px;

    @media (max-width: 575px){
        padding-left: 12px;
    }
    > h3{
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 12px;

        @media (max-width: 1159px){
            font-size: 20px;
        }

        &:lang(hy){

            @media (max-width: 575px){
                font-size: 20px;
            }
        }
    }
    > p{
        color: #898989;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;

        @media (max-width: 1159px){
            font-size: 16px;
        }

        &:lang(hy){
            font-size: 16px;

            @media (max-width: 575px){
                font-size: 15px;
            }
        }
    }
}
.theme-11-course-section{
    padding: 60px 0 60px;

    @media (max-width: 767px){
        padding: 36px 0 36px;
    }
}
.theme-11-special{
    padding: 4vw 24px 4vw 11vw;

    @media (max-width: 1279px){
        padding: 4vw 24px 4vw 4vw;
    }
    @media (max-width: 767px) {
        padding: 24px;
    }
}
.theme-11-special-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.theme-11-special-content{
    background-color: rgba(11, 22, 65, 0.8);
    border-radius: 4px;
    padding: 39px 30px;
    max-width: 420px;
    position: relative;
    z-index: 1;
    color: #ffffff;

    @media (max-width: 767px){
        padding: 24px;
    }
    i{
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        display: block;
        margin-bottom: 16px;

        @media (max-width: 767px){
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    b{
        font-weight: 600;
        font-size: 35px;
        line-height: 41px;
        color: #25D2FE;
        margin-bottom: 9px;
        display: block;

        @media (max-width: 767px){
            font-size: 26px;
            line-height: 30px;
        }
    }
    p{
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 36px;

        @media (max-width: 767px){
            font-size: 16px;
            margin-bottom: 24px;

            &:lang(hy){
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
.theme-11-people{
    padding: 60px 0 72px;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px){
        padding: 48px 0 48px;
        flex-wrap: wrap;
    }
}
.theme-11-people-left{
    padding-left: 11vw;
    width: 46%;
    padding-right: 24px;

    @media (max-width: 1279px){
        padding-left: 3vw;
        padding-right: 20px;
    }
    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 0 24px;
    }
    h2{
        text-align: left;
    }
}
.theme-11-people-left-block{
    padding: 24px 0;
}
.theme-11-people-left-info{
    padding-left: 20px;

    @media (max-width: 991px){
        padding-left: 15px;
    }
    p{
        color: #555557;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 12px;

        @media (max-width: 991px){
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #030303;
        margin-bottom: 1px;
    }
    h4{
        font-size: 16px;
        line-height: 22px;
        color: #030303;
        margin-bottom: 29px;

        @media (max-width: 991px){
            margin-bottom: 20px;
        }
    }
}
.theme-11-people-quot{

    @media (max-width: 991px){
        width: 40px;
    }
}
.theme-11-people-right{
    width: 54%;
    padding-right: 24px;
    padding-left: 3.3vw;
    padding-bottom: 4%;
    height: 40vw;
    max-height: 499px;

    @media (max-width: 991px){
        width: 50%;
        padding-right: 14px;
        padding-left: 1.5vw;
    }
    @media (max-width: 767px){
        width: 100%;
        height: 75vw;
        padding: 0 14px;
    }
}
.theme-11-people-right-img{
    padding: 10px;
    height: 100%;

    img{
        width: 255px;
        border-radius: 5px;
        object-fit: cover;
        height: 100%;
    }
}
.theme-11-yello-block{
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45%;
    background: #5B3EEF;
    border-radius: 4px 0 0 4px;
}
.theme-faq{
    background-color: var(--color-2);
    color: #ffffff;
    padding: 72px 0;

    @media (max-width: 767px){
        padding: 48px 0;
    }
}
.theme-all-form .theme-all-form-bg{
    opacity: 1;
}
